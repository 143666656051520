<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
<!--        <el-form-item prop="roleCode">-->
<!--          <el-input-->
<!--              v-model="query.roleCode"-->
<!--              clearable-->
<!--              placeholder="请输入角色编号"-->
<!--              prefix-icon=""-->
<!--              style="width: 260px"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
        <el-form-item prop="roleName">
          <el-input
              v-model="query.roleName"
              clearable
              placeholder="请输入角色名称"
              prefix-icon=""
              class="width260"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ pagination.totalSize }}</div>
        </div>
      </div>
    </div>
    <div class="content-box section">
      <div class="row_new padding-bottom19">
        <el-button type="primary" size="small" @click="doAdd">新增</el-button>
      </div>
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column
            align="center"
            label="角色编码"
            prop="roleCode"
            width="300"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="角色名称"
            prop="roleName"
            min-width="300"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="角色描述"
            prop="description"
            min-width="300"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="扩展信息"
            prop="extendInfo"
            width="300"
        >
        </el-table-column>
        <!--          <el-table-column-->
        <!--              align="center"-->
        <!--              label="是否内置"-->
        <!--              prop="isBuiltIn"-->
        <!--              width="300"-->
        <!--          >-->
        <!--            <template slot-scope="scope">-->
        <!--              <span>{{ scope.row.isBuiltIn === 0 ? "否" : "是" }}</span>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--          <el-table-column-->
        <!--              align="center"-->
        <!--              label="前端端口"-->
        <!--              prop="frontEndPortKey"-->
        <!--              width="300"-->
        <!--          >-->
        <!--          </el-table-column>-->
        <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="220"
        >
          <template slot-scope="scope">
            <!--              v-if="scope.row.isBuiltIn!==1"-->
            <el-button
                class="common-text"
                size="small"
                type="text"
                @click="doEdit(scope.row)"
            >编辑
            </el-button
            >
            <el-button
                class="common-text"
                size="small"
                type="text"
                @click="setPower(scope.row)"
            >设置权限
            </el-button
            >
            <el-button
                v-if="scope.row.isBuiltIn!==1"
                class="common-text"
                size="small"
                type="text"
                @click="goDelete(scope.row.id)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog width600"
        title="导出选项"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
    <!-- 添加或编辑角色 -->
    <el-dialog
        :before-close="addOrEditDialogBeforeClosed"
        :title="title"
        :visible.sync="addOrEditDialogVisible"
        append-to-body
        class="common-dialog"
        width="400px"
    >
      <div v-loading="isAddLoading">
        <el-form
            ref="model"
            :inline="true"
            :model="model"
            :rules="rules"
            size="medium"
        >
          <el-form-item
              label="角色编号"
              prop="roleCode"
              label-width="100px"
              required
          >
            <el-input
                v-model="model.roleCode"
                clearable
                placeholder="请输入角色编号"
                prefix-icon=""
                class="width400"
            ></el-input>
          </el-form-item>
          <el-form-item
              label="角色名称"
              prop="roleName"
              label-width="100px"
              required
          >
            <el-input
                v-model="model.roleName"
                clearable
                placeholder="请输入角色名称"
                prefix-icon=""
                class="width400"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色描述"
                        label-width="100px"
                        prop="description">
            <el-input
                v-model="model.description"
                clearable
                placeholder="请输入角色描述"
                prefix-icon=""
                class="width400"
                type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item label="扩展信息"
                        label-width="100px"
                        prop="extendInfo">
            <el-input
                v-model="model.extendInfo"
                clearable
                placeholder="请输入扩展信息"
                prefix-icon=""
                class="width400"
                type="textarea"
            ></el-input>
          </el-form-item>
<!--          <el-form-item label="是否内置" label-width="100px" prop="isBuiltIn">-->
<!--            <el-radio-group v-model="model.isBuiltIn">-->
<!--              <el-radio label="0">否</el-radio>-->
<!--              <el-radio label="1">是</el-radio>-->
<!--            </el-radio-group>-->
<!--          </el-form-item>-->
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button @click="cancelAddOrEdit">取 消</el-button>
          <el-button type="primary" @click="saveAddOrEdit">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 设置权限弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="setPowerDialogFormVisible"
        class="common-dialog"
        title="设置权限"
        width="600px"
    >
      <div v-loading="isSetLoading">
        <el-descriptions :column="1" border size="small">
          <el-descriptions-item>
            <template slot="label" style="width: 40%"> 角色</template>
            {{ setPowerData.roleName }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
          <template slot="label" style="width: 40%"> 选中缺省城区 </template>
        </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label" style="width: 40%"> 权限</template>
            <el-tree
                ref="tree"
                :check-strictly="true"
                :data="treeData"
                :default-checked-keys="checkPermissions"
                :props="defaultProps"
                highlight-current
                node-key="id"
                show-checkbox
            >
            </el-tree>
          </el-descriptions-item>
        </el-descriptions>
        <div
            slot="footer"
            class="dialog-footer margin-top20"
            style="text-align: right;"
        >
          <el-button @click="cancelSetPermission">取 消</el-button>
          <el-button type="primary" @click="saveRolePermission">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import {checkToken, doExport} from "@/api/common";
import {
  addRole,
  getPermission,
  getRoleList,
  getRolePermission,
  removeRole,
  saveRolePermission,
  updateRole,
} from "@/api/system";
import {MessageBox} from "element-ui";

export default {
  name: "role_manage",
  mixins: [BaseMixin],
  data() {
    return {
      isLoading: false,
      query: {
        roleCode: "",
        roleName: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      dataList: [1],
      addOrEditDialogVisible: false, //添加运营管理人员弹窗
      title: "",
      isAddLoading: false,
      model: {
        id: "",
        roleCode: "",
        roleName: "",
        description: "",
        extendInfo: "",
        isBuiltIn: "1",
        parentId: "", //角色图标（父级ID）暂时不用
        roleImage: "", //角色图标（暂时不用）
      },
      rules: {
        roleCode: [{required: true, message: "角色编号不能为空"}],
        roleName: [{required: true, message: "角色名称不能为空"}],
      },
      setPowerDialogFormVisible: false, //设置权限弹窗
      isSetLoading: false,
      setPowerData: {
        roleId: "",
        roleName: "",
      }, //设置权限数据
      treeData: [], //权限列表
      // 默认勾选的节点
      checkPermissions: [],
      defaultProps: {
        children: "children",
        label: "permissionName",
      },
    };
  },
  mounted() {
    checkToken()
        .then(res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            this.getPermission();
          }
        })
  },
  methods: {
    cancelSetPermission() {
      this.setPowerDialogFormVisible = false;
    },
    switchListToTree(dataList, parentId) {
      const menuObject = {};
      dataList.forEach((item) => {
        item.children = [];
        menuObject[item.id] = item;
      });

      return dataList.filter((item) => {
        if (item.parentId !== parentId) {
          menuObject[item.parentId].children.push(item);
          return false;
        }
        return true;
      });
    },
    doSort(dataList) {
      dataList.sort((item1, item2) => {
        return item1.displayOrder - item2.displayOrder;
      });

      dataList.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children = this.doSort(item.children);
        }
      });
      return dataList;
    },
    async getPermission() {
      const res = await getPermission();
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.originPermissionList = returnObject;
        this.treeData = this.doSort(this.switchListToTree(returnObject, ""));
      }
    },
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.searchDate = [];
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      this.isLoading = true;
      const params = this.paramFormat(this.query);
      const res = await getRoleList(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.records;
        this.pagination.totalSize = returnObject.total;
        this.pagination.totalPages = returnObject.pages;
        this.form.exportSize = returnObject.total;
      }
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport("/platform/roleadmin/getrole", params, "角色管理.xlsx");
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //添加
    doAdd() {
      this.title = "添加角色";
      this.addOrEditDialogVisible = true;
    },
    //取消添加或编辑角色
    cancelAddOrEdit() {
      this.addOrEditDialogVisible = false;
      this.$refs.model.resetFields();
    },
    // 添加/维护角色信息弹窗关闭时清理表单
    addOrEditDialogBeforeClosed(done) {
      this.$refs.model.resetFields();
      done();
    },
    //编辑角色
    doEdit(e) {
      this.title = "编辑角色";
      this.model.id = e.id;
      this.model.roleCode = e.roleCode;
      this.model.roleName = e.roleName;
      this.model.description = e.description;
      this.model.extendInfo = e.extendInfo;
      this.model.isBuiltIn = e.isBuiltIn.toString();
      this.addOrEditDialogVisible = true;
    },
    //确定添加或编辑角色
    saveAddOrEdit() {
      this.$refs.model.validate((valid) => {
        if (valid) {
          this.isAddLoading = true;
          if (this.model.id === "") {
            addRole(this.model).then((res) => {
              setTimeout(() => {
                this.isAddLoading = false;
                if (res && res.code === 30 && res.result) {
                  this.$message.success("保存成功！");
                  this.addOrEditDialogVisible = false;
                  this.$refs.model.resetFields();
                  this.doSearch();
                }
              }, 2000);
            });
          } else {
            updateRole(this.model).then((res) => {
              setTimeout(() => {
                this.isAddLoading = false;
                if (res && res.code === 30 && res.result) {
                  this.$message.success("编辑成功！");
                  this.addOrEditDialogVisible = false;
                  this.$refs.model.resetFields();
                  this.doSearch();
                }
              }, 2000);
            });
          }
        } else {
          return false;
        }
      });
    },
    //删除
    goDelete(e) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const body = {
          id: e,
        };
        this.isLoading = true;
        removeRole(body).then((res) => {
          setTimeout(() => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("删除成功！");
              this.doSearch();
            }
          }, 1000);
        });
      });
    },
    //设置权限
    setPower(e) {
      this.setPowerDialogFormVisible = true;
      this.setPowerData.roleId = e.id;
      this.setPowerData.roleName = e.roleName;

      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);
        this.getRolePermission(e.id);
      });
    },
    async saveRolePermission() {
      debugger
      let checkedPermission = this.$refs.tree.getCheckedNodes(false, true);
      if (checkedPermission.length === 0) {
        this.$message.warning("请勾选要选中的权限!");
        return;
      }

      // checkedPermission = checkedPermission.map((item) => {
      //   return {
      //     permissionId: item.id,
      //     regionId: "370200",
      //   };
      // });

      const res = await saveRolePermission({
        roleId: this.setPowerData.roleId,
        powerId: checkedPermission.map((item) => item.id),
      });

      if (res && res.code === 30 && res.result) {
        this.$message.success("保存成功！");
        this.$refs.tree.setCheckedKeys([]);
        this.setPowerDialogFormVisible = false;
      }
    },
    //获取角色权限列表
    getRolePermission(roleId) {
      this.isSetLoading = true;
      getRolePermission(roleId).then((res) => {
        this.isSetLoading = false;
        if (res && res.code === 30 && res.result) {
          this.checkPermissions = res.returnObject.map((item) => item.permissionItemId);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
